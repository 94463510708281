import { shortServerFormat } from '@tenant/utils/day'
import { CURRENCIES } from '@tenant/utils/constants'
import BigNumber from 'bignumber.js'
export const useExchangeRate = (currency) => {
    const { defaultCurrency } = useCurrencies()
    const rate = ref(null)

    watch(
        () => currency.value,
        () => {
            if (!currency.value) {
                return
            }

            rate.value = {
                date: shortServerFormat(currency.value?.rate_updated_at),
                rate: currency.value?.rate,
            }
        },
        { immediate: true, deep: true }
    )

    const iso = computed(() => {
        if (currency.value?.iso) {
            return currency.value?.iso
        }

        if (defaultCurrency.value) {
            return defaultCurrency.value.iso
        }

        return CURRENCIES.eur.iso
    })

    const factor = computed({
        get() {
            if (!rate.value?.rate) {
                return 1
            }

            return new BigNumber(1 / rate.value?.rate)
                .decimalPlaces(6)
                .toNumber()
        },
        // setter
        set(newValue) {
            rate.value.rate = 1 / newValue
        },
    })

    return {
        rate,
        iso,
        factor,
    }
}
